<template>
  <div class="card">
    <div class="card-body">

      <package-features :item="order.package"
      :attributes="cart.attributes" class="mb-4" />

      <addons :currencySymbol="order.currency_symbol"
       :addons="cart.addons" class="mb-4" />

      <div class="">
        <requirement-list :requirements="cart.requirements"/>
      </div>
            
    </div>
  </div>
</template>

<script>
import RequirementList from "./RequirementList.vue"
import Addons from "./Addons.vue"
import PackageFeatures from '@/components/order/PackageFeatures.vue'

export default {
  components: {
    RequirementList,
    Addons,
    PackageFeatures,
  },
  data() {
    return {
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
    orderPackage(){
      return this.order.package
    },
    item(){
      return this.order.product
    },
  },
}

</script>

<style type="text/css" scoped>

    sub{font-size: 12px;}

    @media(max-width: 580px){
      .order-details-header{
        margin-bottom: 1.5em;

      }
    }
</style>
